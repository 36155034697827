import template from './add_structure.html';

class SettingsAddStructureViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.countries = ko.observableArray();
		this.imported_holidays = ko.observableArray();
		this.departments = [];
		this.selected_department = ko.observable();
	};

	close()
	{
		this.dialog.close();
	}

	async add_structure()
	{
		if (this.departments.includes(this.selected_department()))
		{
			Grape.alerts.alert({ type: 'error', message: tr('Department already exists'), title: tr('Error') });
			return;
		}

		let res = await Grape.fetches.postJSON('/payment/department_upsert', {
			department: this.selected_department()
		});

		if (res.status != 'ERROR')
			Grape.alerts.alert({ type: 'success', message: tr('Successfully added department.'), title: tr('Success') });
		else
			Grape.alerts.alert({ type: 'error', message: tr('Error occurred while adding department...'), title: tr('Error') });

		this.dialog.close();
	};
};

class SettingsAddStructurePage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SettingsAddStructureViewModel(this);
	};

	async init()
	{
		let res = await Grape.fetches.getJSON('/api/record', {
			schema: 'grape_payment_approval',
			table: 'department',
			limit: 1000000
		});

		if (res.records)
		{
			for (let i = 0; i < res.records.length; i++)
				this.viewModel.departments.push(res.records[i].department); 
		}
	};
};

export default {
	name: 'AddStructure',
	dialog_class: SettingsAddStructurePage,
	template: template,
	template_filename: 'translation/dialogs/add_structure',
	provider: 'ps'
};
