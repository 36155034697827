import template from './payment.html';

class GrapePaymentViewModel
{
	constructor (page)
	{
		this.page = page;
		this.filter = ko.observableArray();
		this.current_user_id = ko.observable();
		this.payments = ko.observableArray();
		this.pending_payments = ko.observableArray();
		// Pagination properties
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);
		this.pagination_summary = ko.observable('');
		this.number_of_records = ko.observableArray([10, 20, 50, 100]);
		this.selected_number_of_records = ko.observable(10);
		this.sort_field = ko.observable('payment_id');
		this.sort_order = ko.observable('DESC');

		this.selected_number_of_records.subscribe((newVal) => {
			this.current_page_size(newVal);
			this.page.updateData();
		});
	};

	page_click(page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	sort_column(column)
	{
		if (column && column.trim() !== '') {
			if (this.sort_field() === column)
				this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
			else {
				this.sort_field(column);
				this.sort_order('ASC');
			}
			this.page.updateData();
		}
	}

	show_payment_help()
	{
		Grape.dialog.open('PaymentHelp', { employee_id: this.page.bindings.id });
	};

	async add_payment()
	{
		await Grape.dialog.open('GrapeAddPayment', null);
		this.page.updateData();
	};

	async edit_payment(row)
	{
		let data = ko.toJS(row);
		data.view = false;
		data.edit = true;
		await Grape.dialog.open('GrapeAddPayment',{ data: data });
		this.page.updateData();
	};

	async approve_payment(row)
	{
		// Make sure we're getting the payment_id correctly
		let payment_id = typeof row === 'object' ? row.payment_id : row;
		
		let response = await Grape.alerts.confirm({type: 'info', title: tr('Confirm'), message: tr('Are you sure you want to approve this payment?')});
		if (response)
		{
			let result = await Grape.fetches.postJSON('/payment/approve', {payment_id: payment_id});
			if (result.status == 'OK')
			{
				Grape.alerts.alert({type: 'success', message: tr('Successfully approved payment'), title: tr('Success')});
				this.page.updateData();
			}
			else if(result.status == 'ERROR')
				Grape.alerts.apiError(result);
			else
				Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
		}
	};

	async reject_payment(row)
	{
		// Make sure we're getting the payment_id correctly
		let payment_id = typeof row === 'object' ? row.payment_id : row;
		
		let response = await Grape.alerts.confirm({type: 'warning', title: tr('Reject this payment?'), message: tr('Are you sure you want to reject this payment?')});
		if (response)
		{
			let reason = await Grape.alerts.prompt({type: 'warning', title: 'Reason', message: tr('Enter reason below')});
			if (reason)
			{
				let result = await Grape.fetches.postJSON('/payment/reject', {payment_id: payment_id, reason: reason});
				if (result.status == 'OK')
				{
					Grape.alerts.alert({type: 'success', message: tr('Successfully rejected.'), title: tr('Success')});
					this.page.updateData();
				}
				else if (result.status == 'ERROR') 
					Grape.alerts.apiError(result);
				else
					Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
			}
			else
				Grape.alerts.alert({type: 'error', message: tr('No rejection reason provided'), title: tr('Error')});
		}
	};

	async delete_payment(row)
	{
		// Make sure we're getting the payment_id correctly
		let payment_id = typeof row === 'object' ? row.payment_id : row;
		
		let response = await Grape.alerts.confirm({type: 'warning', title: tr('Confirm'), message: tr('Are you sure you want to delete this payment?')});
		if (response)
		{
			let result = await Grape.fetches.postJSON('/payment/delete', {payment_id: payment_id});
			if(result.status == 'OK')
			{
				Grape.alerts.alert({type: 'success', message: tr('Successfully deleted'), title: tr('Success')});
				this.page.updateData();
			}
			else if(result.status == 'ERROR')
				Grape.alerts.apiError(result);
			else
				Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
		}
	};

	async view_payment(row)
	{
		let data = ko.toJS(row);
		data.view = true;
		data.edit = false;
		
		if (data.status == 'rejected')
			data.view = true;

		await Grape.dialog.open('GrapeAddPayment', { data: data });
		this.page.updateData();
	};

	async view_payment_approval(row)
	{
		let data = ko.toJS(row);
		data.view = true;
		data.approval = true;
		data.edit = false;

		await Grape.dialog.open('GrapeAddPayment', { data: data });
		this.page.updateData();
	};
};

class GrapePaymentPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new GrapePaymentViewModel(this);
		
	};

	async init()
	{
		document.title = tr('My Payments');
		window.current_page_title(tr('My Payments'));
		await this.updateData();
	}

	async updateData()
	{
		let filter = [];
		if (this.viewModel.filter().length > 0)
			filter = ko.toJS(this.viewModel.filter());

		let options = {
			table: 'v_payment_list', 
			schema: 'grape_payment_approval',
			offset: (this.viewModel.current_page_number() - 1) * this.viewModel.current_page_size(),
			limit: this.viewModel.current_page_size(),
			sortfield: this.viewModel.sort_field(),
			sortorder: this.viewModel.sort_order(),
			filter: filter
		};

		let res = await Grape.fetches.getJSON('/api/record', options);
		if (!res.records)
			res.records = [];

		this.viewModel.payments(res.records);

		// Update pagination information
		this.viewModel.page_count(Math.ceil(res.total / this.viewModel.current_page_size()));
		let start = options.offset + 1;
		let end = Math.min(options.offset + res.records.length, res.total);
		this.viewModel.pagination_summary(`Showing ${start} to ${end} of ${res.total} entries`);
		
		let res_pending = await Grape.fetches.postJSON('/payment/get_pending_list', {});
		if (!res_pending.records)
			res_pending.records = [];

		this.viewModel.pending_payments(res_pending);
	}
};

export default {
	route: `[/]payment_approval/payment`,
	page_class: GrapePaymentPage,
	template: template,
	template_filename: 'translation/payment'
};