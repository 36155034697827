import template from './payment_users_search.html';

class PaymentUsersSearchViewModel
{
	constructor (params)
	{
		this.params = params;

		this.payment_users = ko_helper.safe_observableArray();
		this.selected_payee = ko_helper.safe_observable(params.selected_payee || null);
		this.search_string = ko.observable('');
		this.disable_field = ko_helper.safe_observable(params.disable_field || false);
		this.display_text = ko.observable();
		this.show_list = ko.observable(false);
		this.place_holder = ko.observable(tr('Search payee...'));

		this.selected_payee.subscribe((val) => {
			if (val !== null)
			{
				if (val.payee)
					this.display_text(val.payee);
				else
					this.display_text(this.selected_payee());
			}
			
			if (this.selected_payee() == null)
				this.display_text(null);

			this.payment_users([]);
		});

		this.search_string.subscribe((value) => {
			this.updateData();
			this.show_list(true);
		});

		document.addEventListener('click', this.handle_outside_click.bind(this));
	}

	async init()
	{
		await this.updateData();
	}

	async updateData()
	{
		let options = {
			table: 'payee',
			schema: 'grape_payment_approval',
			limit: 1000000,
			sortorder: 'ASC',
			sortfield: 'payee',
			filter: [{ value: true, operand: '=', field: 'active' }]
		};

		if (this.search_string() && this.search_string() != '')
		{
			options.filter.push({
				field: 'payee',
				operand: 'ILIKE',
				value: `%${this.search_string()}%`,
			});

			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
				if (result.records != null)
					this.payment_users(result.records);
		}
		else
			this.payment_users([]);

		if (this.payment_users().length == 0 && this.search_string())
			this.selected_payee(this.search_string());
	}

	employee_click (v, e)
	{
		this.selected_payee(v);
		this.show_list(false);
	}

	handle_outside_click(event)
	{
		// Check if the click is outside the component
		if (!event.target.closest('.requested-by-search-component'))
			this.show_list(false);
	}

	dispose()
	{
		// Remove the click event listener when the component is disposed
		document.removeEventListener('click', this.handle_outside_click);
	}
}

export default {
	name: 'payment_users_search',
	viewModel: PaymentUsersSearchViewModel,
	module_type: 'ko',
	template: template,
	template_filename: 'translation/components/payment_users_search'
};