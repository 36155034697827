
import template from './payment.html';

class GrapeAddPaymentViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;

		this.payment_id = ko.observable();
		this.payee = ko.observable();
		this.payee_id = ko.observable();
		this.invoice_number = ko.observable();
		this.order_number = ko.observable();
		this.total_amount = ko.observable();
		this.description = ko.observable();
		this.invoice_date = ko.observable();
		this.payment_date = ko.observable();
		this.requested_by = ko.observable();
		this.requested_by_id = ko.observable();
		this.approval_person = ko.observableArray([]);
		this.authorized_by_ids = ko.observableArray([]);
		this.status = ko.observable();
		this.approval = ko.observable(false);
		this.reason = ko.observable();
		this.authorized_by_list = ko.observableArray([]);
		this.department_id = ko.observable();
		this.allowed_to_view_download_requisition = ko.observable(false);
		this.allowed_to_upload  = ko.observable(true);
		this.edit_mode  = ko.observable(false);
		this.pop_email = ko.observable();
		this.view_payee = ko.observable();
		this.view_requested_by = ko.observable();
		this.authorized_by_id = ko.observable();
		this.authorized_by = ko.observable();
		this.paid = ko.observable();

		this.filename = ko.observable();
		this.attachments = ko.observableArray();
		this.attachments_encoded = ko.observableArray();
		this.new_files = ko.observableArray();
		this.has_attachment = ko.observable(false);
		this.batch_file = ko.observable();
		this.person_approved = ko.observableArray([]);

		this.payment_fields_lookup = ko.observableArray();
		this.extra_fields = ko.observableArray();
		this.payment_category = ko.observableArray();
		this.payment_category_selected = ko.observable();
		this.selected_department = ko.observable();

		// search
		this.selected_payee = ko.observable(null);
		this.selected_request_user = ko.observable(null);
		this.selected_approval_user = ko.observable(null);

		this.payment_fields = ko.observable();
		this.new_name = ko.observable('');
		this.file_name = ko.observable('');
		this.disable_fields = ko.observable(false);
		this.edit = ko.observable(false);
		this.departments = ko.observable(false);
		this.approval_route = ko.observableArray();
		this.approval_user_id = ko.observable();
		this.priority = ko.observable();

		this.upload_place_holder = ko.observable(tr('Upload is optional'));

		this.selected_payee.subscribe((val) => {
			this.payee_id(val.payee_id);
			this.payee(val);
		});

		this.selected_request_user.subscribe((val) => {
			if (val !== null)
			{
				this.requested_by_id(val.user_id);
				this.requested_by(val.fullnames);
			}
		});

		this.selected_approval_user.subscribe((val) => {
			if (val !== null)
			{
				this.authorized_by(val);
				this.authorized_by_id(val.user_id);
			}
		});

		this.payment_category_selected.subscribe((val) => {
			this.dialog.load_payment_category();

			if (this.selected_department() != null && this.payment_category_selected() != '')
				this.dialog.load_approval_structure();
		});

		this.selected_department.subscribe((val) => {
			if (this.selected_department() != null && this.payment_category_selected() != '')
				this.dialog.load_approval_structure();
		});

		this.file_name.subscribe((val) => {
			let filename = val;
			filename = filename.substr(0, filename.lastIndexOf('.')) || filename;

			if (this.new_name() == '' || this.new_name() == null)
				this.new_name(filename);
		});

		this.validate_date_field('invoice_date');
	};

	close()
	{
		this.dialog.close();
	};

	 validate_date_field(date_field)
	 {
		document.getElementById(date_field).addEventListener('input', function (e) {
			const date = e.target.value;
			const year = date.split('-')[0];

			if (year.length > 4)
			{
				e.target.value = '';
				Grape.alerts.alert({type: 'error', message: tr('Please enter a valid year for invoice date with 4 digits.'), title: tr('Error')});
			}
		});
	}

	add_approval_person()
	{
		if (!this.authorized_by() || !(this.authorized_by_id())) 
			Grape.alerts.alert({type: 'error', message: tr('Please enter a valid employee name to add to this field.'), title: tr('Error')});
		else
		{
			this.approval_person.push({
				'name': this.authorized_by().fullnames,
				'id': this.authorized_by_id()
			});
			this.selected_approval_user(null);
			this.authorized_by_id(null);
			this.authorized_by(null);
		};
	};

	attachPaymentDateValidation() {
		this.validate_date_field('payment_date');
	}

	clean_authorisation_route()
	{
		this.approval_person.removeAll();
	};

	remove_approval_person(data)
	{
		Grape.alerts.alert({ type: 'success', message: tr('Approval person ') + data.name + ' ' + tr('removed'), title: tr('Success') });
		this.approval_person.remove(data);
	}

	async approve_payment(res)
	{
		let route_priority = await Grape.fetches.getJSON('/api/record', {
			table: 'approval_route',
			schema: 'grape_payment_approval',
			limit: 1,
			filter: [{value: 'in_progress',operand: '=',field: 'status'},{value: res.payment_id, op: '=', field: 'payment_id'}]
		});

		if (route_priority.records[0].priority)
		{
			let approval_route = res.approval_route();
			let current_user_routes = approval_route.filter(item =>
				item.status === 'in_progress'
			);
			let in_progress_route = approval_route.find(item =>
				item.status === 'in_progress'
			);

			if (current_user_routes.length > 0) {
				let user_route = current_user_routes.find(route => route.user_id === this.approval_user_id());

				if (!user_route) // Proceed if a different user is needed for approval
				{ 
					Grape.alerts.alert({ type: 'warning', message: tr('This payment is currently at priority level ') + in_progress_route.priority + tr(' and requires approval from ') + in_progress_route.fullnames,
						title: tr('Warning')
					});
					return;
				}
			}
		}

		let response = await Grape.alerts.confirm({type: 'success', title: tr('Confirm'), message: tr('Approve this payment?')});
		if (response)
		{
			let result = await Grape.fetches.postJSON('/payment/approve', {payment_id: res.payment_id()});
			if (result.status == 'OK')
			{
				Grape.alerts.alert({type: 'success', message: tr('Payment successfully approved.'), title: tr('Success')});
				this.dialog.close();
			}
			else if (result.status == 'ERROR')
				Grape.alerts.apiError(result);
			else
				Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
		}
	};

	async reject_payment(res)
	{
		let response = await Grape.alerts.confirm({type: 'danger', title: tr('Confirm'), message: tr('Reject this payment?')});
		if (response)
		{
			let reason = await Grape.alerts.prompt({type: 'danger', title: tr('Rejecting payment?'), message: tr('Please state reason for rejecting this payment?'),
				accept_text: tr('Save'), cancel_text: tr('Cancel'), has_input: true});

			if (reason !== null)
			{
				let result = await Grape.fetches.postJSON('/payment/reject', { payment_id: res.payment_id(), reason: reason.prompt });
				if (result.status == 'OK')
				{
					Grape.alerts.alert({type: 'success', message: tr('Successfully rejected.'), title: tr('Success')});
					this.dialog.close();
				}
				else if (result.status == 'ERROR')
					Grape.alerts.apiError(result);
				else
					Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
			}
			else if (reason == null || reason == '')
				Grape.alerts.alert({type: 'error', message: tr('Invalid reason provided.'), title: tr('Error')});
		};
	};

	async delete_document(v, e)
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: tr('Confirm'), message: tr('Are you sure you want to remove this document?')});
		if (response)
		{
			let data = ko.toJS(v);

			this.new_files.remove(v);
			let res = await Grape.fetches.postJSON('/payment_documents/delete_document', data);
			if (res.status == 'OK')
			{
				Grape.alerts.alert({ type: 'success', message: tr('Successfully deleted entry.'), title: tr('Success') });
				this.attachments.remove(v);
			}
			else if (res.status == 'ERROR')
				Grape.alerts.apiError(res);
			else
				Grape.alerts.alert({ type: 'error', message: tr('Something went wrong...'), title:  tr('Error')});
		}
	};

	download_requisition()
	{
		let url = '/download/payment/' + this.payment_id();
		window.open(url);
	};

	upload_attachment()
	{
		let fileInput = document.getElementById('batch_file');
		let same_name = false;

		// Loop through each file selected for upload
		for (let i = 0; i < fileInput.files.length; i++)
		{
			let file = fileInput.files[i];

			// Check for duplicates in the current attachments
			for (let j = 0; j < this.attachments().length; j++)
			{
				if (file.name == this.attachments()[j].name)
				{
					Grape.alerts.alert({ type: 'error', title: tr('Error'), message: tr('File with the same name already attached') });
					same_name = true
				}
			}
			if (same_name)
				break;
		}

		if (!same_name)
		{
			// If no duplicates were found, proceed to add new files
			for (let i = 0; i < fileInput.files.length; i++)
			{
				let file = fileInput.files[i];
				let path = URL.createObjectURL(file);
				this.new_files.push(file);
				this.attachments.push({ name: file.name, href: path });
			}

			this.has_attachment(true);
		}
		fileInput.value = ''; 
		$('#upload_input').val('');
	}

	async save_payment_and_send(v, e)
	{
		let data = ko.toJS(v);

		if (!this.payment_category_selected())
			Grape.alerts.alert({type: 'error', message: tr('Please select a payment type'), title: tr('Error')});
		else if (!this.invoice_date())
		{
			await Grape.alerts.alert({type: 'error', message: tr('Invoice date missing'), title: tr('Error')});
			window.document.getElementById('invoice_date').focus();
		}
		else if (!this.payment_date())
		{
			await Grape.alerts.alert({type: 'error', message: tr('Payment date missing'), title: tr('Error')});
			window.document.getElementById('payment_date').focus();
		}
		else if (!this.payee())
		{
			await Grape.alerts.alert({type: 'error', message: tr('Payee missing'), title: tr('Error')});
			window.document.getElementById('payee_id').focus();
		}
		else if (!this.requested_by())
		{
			await Grape.alerts.alert({type: 'error', message: tr('Requester missing'), title: tr('Error')});
			window.document.getElementById('requested_by').focus();
		}
		else if (this.approval_route().length == 0 && this.approval_person().length == 0)
		{
			await Grape.alerts.alert({type: 'error', message: tr('Authoriser missing'), title: tr('Error')});
			window.document.getElementById('authorized_byText').focus();
		}
		else if (!this.total_amount())
		{
			await Grape.alerts.alert({type: 'error', message: tr('Total amount missing'), title: tr('Error')});
			window.document.getElementById('total_amount').focus();
		}
		else if (isNaN(this.total_amount()))
		{
			await Grape.alerts.alert({type: 'error', message: tr('Value entered is not a valid number'), title: tr('Error')});
			window.document.getElementById('total_amount').focus();
		}

		else
		{
			if (this.payee_id())
				this.payee(this.payee().payee);

			let payment_to_save = {
				payment_id: data.payment_id || null,
				payee_id: this.payee_id() || null,
				payee: this.payee(),
				category: data.payment_category_selected,
				invoice_number: this.invoice_number(),
				order_number: this.order_number(),
				invoice_date: this.invoice_date(),
				payment_date: this.payment_date(),
				total_amount: this.total_amount(),
				description: this.description(),
				requested_by_id: this.requested_by_id(),
				authorized_by: this.authorized_by(),
				pop_email: this.pop_email(),
				authorized_by_ids: [],
				edit_mode: false,
				send_payment: true,
				save_payment: true,
				department_id: this.selected_department()
			};

			payment_to_save.fields = [];
			let payment_fields = (this.payment_fields_lookup());

			payment_fields.forEach((category) => {
				payment_to_save.fields.push({
					field_name: category.field_name, 
					default_value: category.default_value,
					type_indicator: category.type_indicator,
					allowed_values: category.allowed_values,
					payment_category_id: category.payment_category_id
				});
			});

			payment_to_save.fields = payment_to_save.fields;
			payment_to_save.requested_by_id = this.requested_by_id();

			let email_approval_person = data.approval_person;
			payment_to_save.approval_person = JSON.stringify(data.approval_person);

			email_approval_person.forEach((f) => {
				payment_to_save.authorized_by_ids.push(f.id);
			});

			payment_to_save.authorized_by_ids = `{${payment_to_save.authorized_by_ids.join(', ')}}`;

			if (this.approval_route().length > 0 && this.total_amount() > this.approval_route()[parseInt(this.approval_route().length) -1].upper_limit &&
				this.approval_route()[parseInt(this.approval_route().length) -1].upper_limit != 0 )
			{
				Grape.alerts.alert({ type: 'error', title: tr('Error'), message: tr('Only Amount of R') + this.approval_route()[parseInt(this.approval_route().length)-1].upper_limit + tr(' or less can be processed by this approval route') });
			}
			else if (this.approval_route().length > 0 && this.total_amount() < this.approval_route()[0].lower_limit )
				Grape.alerts.alert({ type: 'error', title: tr('Error'), message: tr('Only Amount of R') + this.approval_route()[0].upper_limit + tr(' or greater can be processed by this approval route') });
			else
			{
				let res = await Grape.fetches.postJSON('/payment/save', payment_to_save);
				if (res.status == 'OK')
				{
					this.new_files().forEach((file) => {
						let formData = new FormData();
						formData.append('payment_id', res.payment_id);
						formData.append('file', file);

						let xhr = new XMLHttpRequest();
						xhr.open('post', '/payment/upload_supporting_documents', true);
						xhr.setRequestHeader('Accept', 'application/json');
						xhr.send(formData);

						xhr.onreadystatechange = () => {
							if (xhr.readyState === 4)
							{
								let result = JSON.parse(xhr.response);
								if (result.status == 'ERROR')
									Grape.alerts.alert({type: 'error', message: result.message, title: tr('Error')});
								else if (result.status == 'OK')
								{}
								else
									Grape.alerts.alert({type: 'error',message: tr('Something went wrong...'), title: tr('Error')});
							}
						}
					});

					Grape.alerts.alert({type: 'success', message: tr('Successfully saved payment.'), title: tr('Success')});
					this.dialog.close();
				}
				else if (res.status == 'ERROR')
					Grape.alerts.alert({ type: 'error', title: tr('Error'), message: res.message });

				this.dialog.close();
			}
		}
	};
};

class GrapeAddPaymentPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new GrapeAddPaymentViewModel(this);

		if (bindings != 'undefined' && bindings != null)
		{
			if (bindings.data.payment_id != 'undefined' || bindings.data.payment_id != null)
			{
				if (bindings.data.view == true)
					this.viewModel.disable_fields(true);
				else
					this.viewModel.disable_fields(false);

				if (bindings.data.edit == true)
					this.viewModel.edit(true);

				if (bindings.data.approval)
					this.viewModel.approval(true);

				this.viewModel.payment_id(bindings.data.payment_id);
				this.viewModel.payment_date(bindings.data.payment_date);
				this.viewModel.description(bindings.data.description);
				this.viewModel.total_amount(bindings.data.total);
				this.viewModel.payee_id(bindings.data.payee);
				this.viewModel.payee(bindings.data.payee);
				this.viewModel.requested_by(bindings.data.requested_by);
				this.viewModel.requested_by_id(bindings.data.requested_by_id);
				this.viewModel.view_payee(bindings.data.payee );
				this.viewModel.view_requested_by( bindings.data.requested_by );

				this.viewModel.order_number(bindings.data.order_number);
				this.viewModel.invoice_number(bindings.data.invoice_number);
				this.viewModel.invoice_date(bindings.data.invoice_date);
				this.viewModel.status(bindings.data.status);
				this.viewModel.reason(bindings.data.reason);
				this.viewModel.allowed_to_view_download_requisition(bindings.data.allowed_to_view_download_requisition);
				this.viewModel.allowed_to_upload(bindings.data.allowed_to_upload);
				this.viewModel.edit_mode(true);
				this.viewModel.paid(bindings.data.paid);
				this.viewModel.selected_payee(bindings.data.payee);
				this.viewModel.selected_request_user(bindings.data.requested_by);
				this.viewModel.pop_email(bindings.data.pop_email);
				this.viewModel.approval_user_id(bindings.data.approval_user_id);
	
				if (this.viewModel.edit_mode)
				{
					if (this.bindings.data.assigned_to)
						this.viewModel.selected_approval_user(this.bindings.data.assigned_to);

					if (this.bindings.data.requested_by)
					{
						this.viewModel.requested_by(this.bindings.data.requested_by);
						this.viewModel.requested_by_id(this.bindings.data.requested_by_id);
						this.viewModel.selected_request_user({
							user_id: this.bindings.data.requested_by_id,
							fullnames: this.bindings.data.requested_by
						});
					}
					else
					{
						this.viewModel.requested_by_id(null);
						this.viewModel.requested_by(null);
						this.viewModel.selected_request_user(null);
					}

					if (this.bindings.data.payee)
					{
						this.viewModel.selected_payee({
							payee: this.bindings.data.payee,
							payee_id: this.bindings.data.payee_id
						});
					}
				}
			}

			$('#batch_file').change(() => {
				$('#upload_input').val($('#batch_file').val().replace('C:\\fakepath\\', ''));
				this.viewModel.filename($('#upload_input').val());
				this.viewModel.upload_attachment();
			});
		}
	};

	async init()
	{
		this.viewModel.approval_route([]);

		// GET DEPARTMENTS
		try {
			let res = await Grape.fetches.getJSON('/api/record', {
				table: 'department',
				schema: 'grape_payment_approval',
				limit: 1000000
			});

			if (res.status != 'ERROR')
			{
				this.viewModel.departments(res.records);
				if (this.bindings != null)
					this.viewModel.selected_department(this.bindings.data.department_id);
			}
			else
				Grape.alerts.apiError(res);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: tr('Error'), message: tr('An error occurred while retrieving departments...')});
			console.error(error);
		}

		// GET PAYMENT CATEGORIES
		try {
			let res = await Grape.fetches.postJSON('/payment/list_payment_category', {});

			if (res.status != 'ERROR')
			{
				this.viewModel.payment_category(res);
				if (this.bindings != null)
				{
					this.viewModel.payment_category_selected(this.bindings.data.category)
					this.load_payment_category();
				}
			}
			else
				Grape.alerts.apiError(res);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: tr('Error'), message: tr('An error occurred while retrieving payment categories...')});
			console.error(error);
		}
		
		if (this.bindings && this.bindings.data)
		{
			// GET APPROVAL ROUTE
			this.viewModel.approval_route([]);
			try {
				let res = await Grape.fetches.getJSON('/api/record', {
					table: 'v_approval_route',
					schema: 'grape_payment_approval',
					limit: 1000000,
					sortfield: 'priority',
					sortorder: 'ASC',
					filter: [{ value: this.bindings.data.payment_id, operand: '=', field: 'payment_id' }]
				});

				if (res.status != 'ERROR')
					this.viewModel.approval_route(res.records);
				else
					Grape.alerts.apiError(res);

			} catch (error) {
				Grape.alerts.alert({type: 'error', title: tr('Error'), message: tr('An error occurred while retrieving the approval route...')});
				console.error(error);
			}

		// GET SUPPORTING DOCUMENTS
			try {
				if (this.bindings != null)
				{
					let res = await Grape.fetches.postJSON('/payment/get_supporting_documents', { payment_id: this.bindings.data.payment_id});
					if (res.status != 'ERROR')
					{
						if (res.documents)
						{
							this.viewModel.attachments_encoded(res.documents);
							for (let i = 0; i < res.documents.length; i++)
							{
								let filename = this.viewModel.attachments_encoded()[i].href.substring(this.viewModel.attachments_encoded()[i].href.lastIndexOf('/') + 1, this.viewModel.attachments_encoded()[i].href.length);
								let encoded_string = btoa(filename);
								this.viewModel.attachments_encoded()[i].href = '/download/payment_documents/' + this.bindings.data.payment_id + '/' + encoded_string;
								this.viewModel.attachments_encoded()[i].filename = encoded_string;
							}
							this.viewModel.attachments(this.viewModel.attachments_encoded());
						}
						else
						{
							this.viewModel.attachments.removeAll();
							this.viewModel.attachments_encoded.removeAll();
						}
					}
					else
						Grape.alerts.apiError(res);
				}
			} catch (error)
			{
				Grape.alerts.alert({type: 'error', title: tr('Error'), message: tr('An error occurred while retrieving the supporting documents...')});
				console.error(error);
			}
			if (this.bindings.data.requested_by)
			{
				this.viewModel.requested_by(this.bindings.data.requested_by);
				this.viewModel.requested_by_id(this.bindings.data.requested_by_id);
				this.viewModel.selected_request_user({
					user_id: this.bindings.data.requested_by_id,
					fullnames: this.bindings.data.requested_by
				});
			}
			else
			{
				this.viewModel.requested_by_id(null);
				this.viewModel.requested_by(null);
				this.viewModel.selected_request_user(null);
			}
		}
	}

	async load_payment_category()
	{
		let data = [];

		if (this.bindings != null)
		{
			if (this.bindings.data.extra_fields != null)
			{
				this.bindings.data.extra_fields.forEach((item) => {
					data.push(item)
				});
				this.viewModel.payment_fields_lookup(data);
			}

			if (this.bindings.data.authorized_by)
			{
				this.viewModel.selected_approval_user({
					fullnames: this.bindings.data.authorized_by,
				});
			}

			// Set requested_by
			if (this.bindings.data.requested_by)
			{
				this.viewModel.selected_request_user({
					fullnames: this.bindings.data.requested_by,
				});
			}

			// Set payee
			if (this.bindings.data.payee)
			{
				this.viewModel.selected_payee({
					payee: this.bindings.data.payee,
					payee_id: this.bindings.data.payee_id
				});
			}
		}
		else
		{
			let res = await Grape.fetches.postJSON('/payment/field_lookups', {});
			if (res)
			{
				res.forEach((f) => {
					if (this.viewModel.payment_category_selected() == f.payment_category_id)
						data.push(f);
				});
				this.viewModel.payment_fields_lookup(data);
			}
		}
	}

	async load_approval_structure()
	{
		if (this.viewModel.selected_department() != null && this.viewModel.payment_category_selected() != undefined && !this.bindings)
		{
			let res = await Grape.fetches.getJSON('/api/record', {
				table: 'v_approval_structure',
				schema: 'grape_payment_approval',
				limit: 1000000,
				sortfield: 'priority',
				sortorder: 'ASC',
				filter: [
					{ value: this.viewModel.selected_department(), operand: '=', field: 'department_id' },
					{ value: this.viewModel.payment_category_selected(), operand: '=', field: 'requisition_type_id' }
				]
			});

			if (res.status != 'ERROR')
				this.viewModel.approval_route(res.records);
		}
	}
};

export default {
	name: 'GrapeAddPayment',
	dialog_class: GrapeAddPaymentPage,
	template: template,
	template_filename: 'translation/dialogs/payment',
	provider: 'ps'
};

