import template from './user_signatures.html';

class UserSignaturesViewModel
{
	constructor (page)
	{
		this.page = page;
		this.user_signatures = ko.observableArray();
		this.search_string = ko.observable('');
		this.place_holder = ko.observable(tr('Search users...'));

		this.search_string.subscribe((val) => {
			this.page.updateData();
		})
	};

	async upload_signature(user_id)
	{
		await Grape.dialog.open('UserSignatureUpload', { user_id: user_id });
		this.page.updateData();
	}
	
	user_signature_help()
	{
		Grape.dialog.open('UserSignatureHelp', { employee_id: this.page.bindings.id });
	};
};

class UserSignatures
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new UserSignaturesViewModel(this);
	}

	init()
	{
		document.title = tr('User Signatures');
		window.current_page_title(tr('User Signatures'));
	}

	async updateData()
	{
		let data = {
			table: 'v_payment_users',
			schema: 'grape_payment_approval',
			limit: 1000000,
			filter_join: 'OR',
			filter: []
		}

		if (this.viewModel.search_string() != '' && this.viewModel.search_string())
		{
			data.filter.push({ 
				value: `%${this.viewModel.search_string()}%`,
				operand: 'ILIKE',
				field: 'fullnames'
			},{
				value: `%${this.viewModel.search_string()}%`,
				operand: 'ILIKE',
				field: 'username'
			})
		}

		let res = await Grape.fetches.getJSON('/api/record', data);

		if (res.status != 'ERROR')
			this.viewModel.user_signatures(res.records);
		else
			Grape.alerts.apiError(res);
	};
};

export default {
	route: '[/]payment_approval/user_signatures',
	page_class: UserSignatures,
	template: template,
	template_filename: 'translation/user_signatures'
};

