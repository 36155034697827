import template from './payment_list.html';

class paymentlistViewModel
{
	constructor (page)
	{
		this.page = page;
		this.records = ko.observableArray();
		this.pending_payments = ko.observableArray();
		this.set_paid_payments = ko.observableArray();
		this.approval_person = ko.observable();
		this.checkedPayment = ko.observable();
		this.selected_radio_option = ko.observable(5);
		this.search_string = ko.observable('');
		this.payments = ko.observableArray();

		this.search_string.subscribe((value) => {
			this.page.updateData();
		});
	};

	async delete_payment(data)
	{
		let url = '/payment/delete';
		let response = await Grape.alerts.confirm({ type: 'warning', title: tr('Confirm'), message: tr('Are you sure you want to delete this payment?')});
		if (response)
		{
			let res = await Grape.fetches.postJSON(url, {payment_id : data.payment_id });
			if (res.status == 'OK')
			{
				Grape.alerts.alert({type: 'success', message: tr('Successfully deleted'), title: tr('Success')});
				this.page.updateData();
			}
			else if(res.status == 'ERROR') 
				Grape.alerts.apiError(res);
			else
				Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
		}
	};

	async view_payment(row)
	{
		let data = ko.toJS(row);
		data.view = true;
		data.paid = true;
		await Grape.dialog.open('GrapeAddPayment', data);
		this.page.updateData();
	};

	async edit_payment(row)
	{
		let data = ko.toJS(row);
		data.view = false;
		await Grape.dialog.open('GrapeAddPayment', data );
		this.page.updateData();
	};
	
	async set_payment(row)
	{
		let data = ko.toJS(row);

		if (data.status == 'completed')
		{
			let res = await Grape.fetches.postJSON('/payment/set_paid', {payment_id : data.payment_id});

			if (res.status == 'OK')
				this.page.updateData();
			else if(res.status == 'ERROR') 
				Grape.alerts.apiError(res);
			else
				Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});

		} else
			Grape.alerts.alert({type: 'warning', message: tr('Only Payments with a status of completed can be set to Paid'), title: tr('Warning')});
	};
	
	async reject_payment(data)
	{
		let response = await Grape.alerts.confirm({ type: 'warning', title: tr('Reject this payment?'), message: tr('Are you sure you want to reject this payment?')});
		if (response)
		{
			let reason = await Grape.alerts.prompt({ type: 'warning', title: tr('Reason'), message: tr('Enter reason below')});
			if (reason && reason != '')
			{
				let res = await Grape.fetches.postJSON('/payment/reject', { payment_id : data, reason: reason });
				if (res.status == 'OK')
				{
					Grape.alerts.alert({type: 'success', message: tr('Successfully rejected.'), title: tr('Success')});
					this.page.updateData();
				}
				else if(res.status == 'ERROR') 
					Grape.alerts.apiError(res);
				else
					Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
			}
			else
				Grape.alerts.alert({type: 'error',  message: tr('No rejection reason provided'), title: tr('Error')});
		}
	};
};

class paymentlistPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new paymentlistViewModel(this);
		this.columns;
	}

	init()
	{
		document.title = tr('Payment List');
		window.current_page_title(tr('Payment List'));

		let buttonstring = [];
		this.columns = [
			{field: 'payment_id', title: tr('ID'), sortable: true, align : 'left', width: '3%'},
			{field: 'requested_by', title: tr('Requested By'), sortable: true , align : 'left', width: '3%'},
			{field: 'date_created', title: tr('Date Created'), sortable: true, align : 'left', width: '3%'},
			{field: 'description', title: tr('Description'), sortable: true, align : 'left', width: '3%'},
			{
				field: 'total',
				title: tr('Total Amount'),
				width: '3%',
				sortable: true,
				align : 'left',
				formatter: (value, row, index) => {
					return 'R ' + row.total;
				}
			},
			{field: 'status', title: tr('Status'), sortable: true, align : 'left', width: '3%'},
			{
				field: 'Action',
				title: tr('Actions'),
				class: 'inline-elements',
				sortable: false,
				width: '5%',
				align : 'center',
				formatter: (value, row, index) => {
					buttonstring = [];
					buttonstring.push('<button class="ps-btn-primary ps-btn-sm view set_payment_button" data-bind="click: () => $root.set_paid_payments()"><span>Paid?</span></button>');
					buttonstring.push('<button class="ps-btn-default ps-btn-sm view view_payment_button"><span class="fa fa-eye-slash"></span></button>');
					return [
					buttonstring
				].join('');
				},
				events:{
					'click .set_payment_button': (e, value, row, index) => {
						this.viewModel.set_payment(row);
					},
					'click .reject_payment_button': (e, value, row, index) => {
						this.viewModel.reject_payment(row.payment_id);
					},
					'click .view_payment_button': (e, value, row, index) => {
						this.viewModel.view_payment(row);
					},
					'click .edit_payment_button': (e, value, row, index) => {
						this.viewModel.edit_payment(row);
					},
					'click .delete_payment_button': (e, value, row, index) => {
						this.viewModel.delete_payment(row);
					}
				}
			}
		];

		tbl_employees = $('#tbl_payments').bootstrapTable({
			columns: this.columns,
			pagination: true,
			pageList: [10, 20, 50, 100],
			pageSize: 10,
			sidePagination: 'server',
			dataToolbar: '#toolbar',
			dataField: 'records',
			onPageChange: (number, size) => {
				this.updateData();
			},
			onSort: (sortName, sortOrder) => {
				this.updateData();
			}
		});
	}

	async updateData()
	{
		let options = {
			table: 'v_subordinate_payments',
			schema: 'grape_payment_approval',
			offset: 0,
			limit: 10,
			filter_join: 'OR',
			sortfield: 'payment_id',
			sortorder: 'DESC'
		};
		let table_options = $('#tbl_payments').bootstrapTable('getOptions');
		
		//LOGIC: Sort instantiate
		if (table_options.sortName && table_options.sortName != '')
		{
			options.sortfield = table_options.sortName;
			options.sortorder = table_options.sortOrder.toUpperCase();
		}

		//LOGIC: Page size and offset instantiate
		if (table_options.pageNumber && table_options.pageSize)
		{
			options.limit = table_options.pageSize;
			options.offset = (table_options.pageNumber - 1) * options.limit;
		}

		//LOGIC: Search instantiate
		if (this.viewModel.search_string() && this.viewModel.search_string() != '' && this.viewModel.search_string() != null)
		{
			options.filter.push({
				value: this.viewModel.search_string(),
				operand: 'ILIKE',
				field: 'payment_id'
			});

			options.filter.push({
				value: this.viewModel.search_string(),
				operand: 'ILIKE',
				field: 'requested_by'
			});

			options.filter.push({
				value: this.viewModel.search_string(),
				operand: 'ILIKE',
				field: 'date_created'
			});

			options.filter.push({
				value: this.viewModel.search_string(),
				operand: 'ILIKE',
				field: 'description'
			});

			options.filter.push({
				value: this.viewModel.search_string(),
				operand: 'ILIKE',
				field: 'total'
			});

			options.filter.push({
				value: this.viewModel.search_string(),
				operand: 'ILIKE',
				field: 'status'
			});
		}

		let res = await Grape.fetches.getJSON('/api/record', options);
		if (res.status != 'ERROR')
			this.viewModel.payments(res.records);
		else 
			res.records = [];
			
		$('#tbl_payments').bootstrapTable('load', res);

		let res_list = await Grape.fetches.postJSON('/payment/get_pending_list', { })
		if (res_list !== 'ERROR')
			this.viewModel.pending_payments(res_list);
	}
};

export default {
	route: '[/]payment_approval/payment_list',
	page_class: paymentlistPage,
	template: template,
	template_filename: 'translation/payment_list'
};


/*
NOT USED?

set_selected_paid()
{
	this.set_paid_payments().forEach(async(id) => {
		let res = await Grape.fetches.postJSON('/payment/set_paid', {payment_id : id});
		
		if (res.status == 'OK')
		{
			Grape.alerts.alert({type: 'success',});
			this.page.updateData();
		}
		else if (res.status == 'ERROR') 
			Grape.alerts.apiError(res);
		else
			Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
	});
};

async approve_payment(data)
{
	let url = '/payment/approve';
	let response = await Grape.alerts.confirm({ type: 'info', title: tr('Confirm'), message: tr('Are you sure you want to approve this payment?')});
	if (response)
	{
		let res = await Grape.fetches.postJSON(url, {payment_id : data });
		if (res.status == 'OK')
		{
			Grape.alerts.alert({type: 'success', message: tr('Successfully approved.'), title: tr('Success')});
			this.page.updateData();
		}
		else if(res.status == 'ERROR') 
		{
			Grape.alerts.apiError(res);
		}
		else{
			Grape.alerts.alert({type: 'error', message: tr('Something went wrong...'), title: tr('Error')});
		}
	}
	else {};//do nothing
};
*/